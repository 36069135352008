import React from "react"
import Link from 'gatsby-link'
import Img from 'gatsby-image'
import grantIcon from "../../images/grant-icon.jpg"
import subsocialIcon from "../../images/subsocial.png"
import emailIcon from "../../images/email.png"
import githubIcon from "../../images/github.png"
import websiteIcon from "../../images/website.png"
import IconLinkComponent from "../icon-link"

const ProjectsTable = ({ data }) => {


    return (
        <div className="table-wrapper">
            <table className="table is is-fullwidth">
                <thead>
                    <tr>
                        <th className="is-size-4 pl-5">Grant</th>
                        <th className="is-size-4">Logo</th>
                        <th className="is-size-4">Name</th>
                        <th className="is-size-4">Description</th>
                        <th className="is-size-4">Website</th>
                        <th className="is-size-4">Github</th>
                        <th className="is-size-4">Subsocial</th>
                        <th className="is-size-4 pr-5">Email</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(item => (
                        <tr key={item.id}>
                            <th className="pl-5">
                                {item.grant && <div className="image is-30x30"><img src={grantIcon}></img></div>}
                            </th>
                            <th>
                                <Img fixed={item.logo?.childImageSharp?.fixed} />
                            </th>
                            <th>
                                <div className="is-size-5 has-text-link">
                                    <Link to={`/${item.id}`}>{item.name}</Link>
                                </div>
                            </th>
                            <th className="is-size-5 has-text-dark">{item.short_description}</th>
                            <th>
                                <IconLinkComponent Link={item.website_link} Icon={websiteIcon} />
                            </th>
                            <th>
                                <IconLinkComponent Link={item.github_link} Icon={githubIcon} />
                            </th>
                            <th>
                                <IconLinkComponent Link={item.subsocial_link} Icon={subsocialIcon} />
                            </th>
                            <th className="pr-5">
                                <IconLinkComponent Link={`mailto:${item.email}`} Icon={emailIcon} />
                            </th>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default ProjectsTable
