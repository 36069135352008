import React from "react"

const IconLinkComponent = (props) => {

    const iconClass = `icon-link
        ${props.Classes ? props.Classes : ''}
        ${props.Link ? '' : 'disabled-icon'}`;

    return (
        <a className={iconClass} href={props.Link}>
            <div className="image is-20x20">
                <img src={props.Icon}></img>
            </div>
        </a>
    )
}

export default IconLinkComponent