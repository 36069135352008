import React from "react"
import Chip from "../chip"

const ProjectsDirectoryHeader = (props) => {

    return (
        <div className="project-directory-header">
            <div className="block">
                <div className="title is-2">{props.Name}</div>
            </div>
            <div className="columns">
                <div className="column columns categories is-mobile">
                    <div className="column is-narrow" style={{ margin: 'auto 0' }}>
                        <div className="title is-3 pr-2">Categories</div>
                    </div>
                    <Chip name="Projects" isActive={props.IsProjects} isLarge={true} Link="/projects" color="light" />
                    <Chip name="Resources" isActive={!props.IsProjects} color="light"
                        isLarge={true} Link="/resources" />

                </div>
            </div>
        </div>
    )
}

export default ProjectsDirectoryHeader
