import { mdiChevronDown } from "@mdi/js"
import Icon from "@mdi/react"
import { useStaticQuery, graphql } from "gatsby"
import React, { useState } from "react"
import CategoriesPannel from "./categories-pannel"
import Chip from "./chip"

const Categories = (props) => {

  const [categoriesPannelIsOpened, setCategoriesPannel] = useState(false);

  function toggleCategoriesPannel() {
    setCategoriesPannel(categoriesPannelIsOpened => !categoriesPannelIsOpened);
  }

  const urlPrefix = props.chosenCategory ? '../' : './';

  return (
    <div className="categories-wrapper my-3">
      <div className="columns is-mobile is-variable is-1">

        <div className="column columns categories-list is-mobile is-variable is-1 pb-0">
          <Chip color="white" name="All"
            isActive={!props.chosenCategory} Link={urlPrefix}
            onClick={props.filterByCategory} />

          {props.Categories.map(category => (
            <Chip key={category.slug} name={category.name} slug={category.slug}
              isActive={category.slug === props.chosenCategory} Link={`${urlPrefix}${category.slug}`}
              onClick={props.filterByCategory} color="white" />
          ))}
        </div>
        <div className="column dropdown-button p-0">
          <span className="icon" onClick={toggleCategoriesPannel}>
            <Icon path={mdiChevronDown} size={1} />
          </span>
        </div>
        <CategoriesPannel chosenCategory={props.chosenCategory} categories={props.Categories} onClose={toggleCategoriesPannel}
          showModal={categoriesPannelIsOpened} filterByCategory={props.filterByCategory} defaultCategoryName="All" />
      </div>
    </div>
  )
}

export default Categories
